//交易分布
let option1 = {
  title: {
    text: '交易分布',
    left: 'center',
    textStyle: {
      color: "#fff",
    },
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'hideTip'
    }
  },
  legend: {
    orient: 'vertical',
    left: 'left',
    textStyle: {
      color: "#fff",
    },

  },
  
  color: ["#0cc2b9", '#0cc258', "#99d115", "#bed34b","#15b5d1","#15d189","#d19f15"],
  series: [
    {
      name: '',
      type: 'pie',
      radius: '50%',
      data: [
        { value: 480, name: '卖水' },
        { value: 350, name: '锁场' },
        { value: 180, name: '约战' },
        { value: 340, name: '整场' },
        { value: 550, name: '散客' },
        { value: 241, name: '飞盘' },
        { value: 354, name: '会员' },
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }
  ]
};

export default option1;