//日收益展示
let option = {
    title: {
        text: '日度流水报表',
        left: 'center',
        textStyle: {
          color: "#fff",
        },
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'hideTip',
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '15%',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        data: ['2023-08-22', '2023-08-23', '2023-08-24', '2023-08-25', '2023-08-26', '2023-08-27', '2023-08-28'],
        axisLabel: {
          show: true,
          textStyle: {
            color: "white",//这里是改变字体颜
          }
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: '元',
        axisLabel: {
          show: true,
          textStyle: {
            color: "white",//这里是改变字体颜
          }
        }
      }
    ],
    series: [
      {
        name: '收入',
        type: 'bar',
        barWidth: '30%',
        color:'#0f59a4',
        data: [22, 32, 23, 27, 44, 32,42],
        label: {
            show: false,
            position: 'top',
        }
      }
    ]
  };
  export default option;