// 月收益展示
let option = {
  title: {
      text: '月度业务核算',
      left: 'center',
      textStyle: {
        color: "#fff",
      },
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'hideTip'
    }
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '15%',
    containLabel: true
  },
  xAxis: [
    {
      type: 'category',
      data: ["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"],
      axisLabel: {
        show: true,
        textStyle: {
          color: "white",//这里是改变字体颜
        }
      }
    }
  ],
  yAxis: [
    {
      type: 'value',
      name: '元',
      color:'white',
      axisLabel: {
        show: true,
        textStyle: {
          color: "white",//这里是改变字体颜
        }
      }
    }
  ],
  series: [
    {
      name: '收入',
      type: 'bar',
      barWidth: '30%',
      color:'#0f59a4',
      data: [22, 32, 23, 27, 44, 32,42,45,14,62,41,21],
      label: {
          show: false,
          position: 'top'
      },
    },
   
  ]
};
export default option;