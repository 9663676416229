<template>
    <div>
      <normal-card>
        <div class="top-field">
          <el-row class="fieldInfo" type="flex" justify="center" align="middle"> 
            <el-col :span="13">
              <el-row>
                <el-col>
                  <div class="fieldName">
                    <span>{{ currentField.fieldName }}</span>
                    <img src="../../../assets/switch.png" class="switch-img" title="切换球场" @click="switchField" />
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col><span>地址：{{ currentField.address }}</span></el-col>
              </el-row>            
            </el-col>
            <el-col :span="11" class="dfac">
              <div><span class="f28">今日球场收入</span></div> 
              <div class="ml20 fnum"><span class="f28">999999.99</span></div> 
              <div class="ml20"><span class="f28">元</span></div> 
            </el-col>
          </el-row>
        </div>
      </normal-card>
      <el-dialog :visible.sync="fieldDialogVisible" title="切换球场">
        <el-table :data="fieldList" stripe>
            <el-table-column label="球场名称" prop="fieldName"></el-table-column>
            <el-table-column label="球场地址" prop="address" width="280"></el-table-column>
            <el-table-column label="联系电话" prop="mobile"></el-table-column>
            <el-table-column label="球场身份" width="100">
            <template slot-scope="scope">
                <span v-if="scope.row.ownerType === 0">球场主</span>
                <span v-if="scope.row.ownerType === 1">管理员</span>
            </template>
            </el-table-column>
            <el-table-column label="切换球场">
            <template slot-scope="scope">
                <el-button type="text" :disabled="checkCurrentField(scope.row)"
                @click="handleSwitch(scope.row)">切换</el-button>
            </template>
            </el-table-column>
        </el-table>
        </el-dialog>
    </div>
</template>
<script>
import normalCard from '@/common/components/normal/NormalCard';
export default {
  components: { normalCard },
  name: "topData",
  data() {
    return {
      dialogMoneyVisible: false,
      // 当前球场
      currentField: {},
      // 球场列表
      fieldList: [],
      fieldDialogVisible: false
    };
  },
  methods: {
    init() {
      this.currentField = localStorage.getItem('currentField') ? JSON.parse(localStorage.getItem('currentField')) : {};
      console.log(this.currentField);
    },
    // 点击切换球场图片
    switchField() {
      this.$http.get('/Field/GetFieldList').then(response => {
        if (response.res.isSuccess) {
          this.fieldList = response.res.data || [];
          this.fieldDialogVisible = true;
        }
      });
    },
    // 判断该行球场是否为当前选择球场
    checkCurrentField(row) {
      return row.fieldId == localStorage.getItem('fieldId');
    },
    // 切换球场操作
    handleSwitch(row) {
      this.$confirm('确认切换球场吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        localStorage.setItem('fieldId', row.fieldId);
        localStorage.setItem('currentField', JSON.stringify(row));
        location.reload();
      });
    }
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
// 球场信息
.top-field {
  height: 100px;
  color: #fff;
  width: calc(100% - 239px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.fieldInfo {
  margin-left: 40px;
}

.switch-img {
  width: 40px;
  height: 30px;
  margin-left: 10px;
  cursor: pointer;
}

.top-field .fieldName {
  font-size: 28px;
  display: flex;
  align-items: center;
}

.addressInfo {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.fnum{
  text-shadow: 1px 1px 1px rgba(0,225,21,36%);
  
}
</style>