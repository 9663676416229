//订单分区
let option1 = {
    title: {
      text: '订单分区',
      left: 'center',
      textStyle: {
        color: "#fff",
      },
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'hideTip'
      }
    },
    legend: {
      orient: 'vertical',
      left: 'left',
      textStyle: {
        color: "#fff",
      },
    },
    label: {
      show: false,
    },
    color: ["#134f7a", '#2b4d21', "#2a343a", "#bed34b","#7cc20c"],
    series: [
      {
        name: '',
        type: 'pie',
        radius: '50%',
        data: [
          { value: 48, name: '拼场' },
          { value: 35, name: '整场' },
          { value: 18, name: '约战' },
          { value: 55, name: '飞盘' },
          { value: 48, name: '锁场' },
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          },
        }
      }
    ]
  };
  
  export default option1;