<!-- 球场报告 -->
<template> 
  <div>
    <top-data></top-data>
    <!-- <div class="container"> -->
    <!-- <div class="total-data">
        <div class="total-area"> -->
    <!-- <div class="totalTr">
            <div style="display: flex;">
              <div class="total-span">
                <img src="https://football-go.oss-cn-hangzhou.aliyuncs.com/pic/15-icon/306-zongshouyi.png" />
                <span>球场总营收</span>
              </div>
              <div class="totleIn">￥52886.09</div>
            </div>
            <div class="fieldName">
              <span>27号足球场</span>
              <span style="font-size: 15px;margin-left: 10px; font-weight: 550;">2023-08-26</span>
            </div>
          </div> -->
    <!-- <div class="data_info"> -->
    <!-- <div class="row-data">
              <div class="preset">
                <div class="value">
                  2324
                  <span style="font-size: 18px;">元</span>
                </div>
                <div class="income">
                  <img src="https://football-go.oss-cn-hangzhou.aliyuncs.com/pic/15-icon/299-sankeshouru.png" />
                  <div class="topic">普客消费</div>
                </div>
              </div>
              <div class="vip-number">
                <div class="value">
                  543.99
                  <span style="font-size: 18px;">元</span>
                </div>
                <div class="income">
                  <img src="https://football-go.oss-cn-hangzhou.aliyuncs.com/pic/15-icon/297-renshu.png" />
                  <div class="topic">会员卡销量</div>
                </div>
              </div>
              <div class="rates-income">
                <div class="value">
                  143.09
                  <span style="font-size: 18px;">元</span>
                </div>
                <div class="income">
                  <img src="https://football-go.oss-cn-hangzhou.aliyuncs.com/pic/15-icon/298-vipshouru.png" />
                  <div class="topic">会员卡收入</div>
                </div>
              </div>
            </div> -->
    <!-- <div class="count_img">
              <img src="https://football-go.oss-cn-hangzhou.aliyuncs.com/pic/15-icon/661-right.png" />
            </div> -->
    <!-- <div class="row-data">
              <div class="online_info" style="margin-bottom: 40px;">
                <div class="value">
                  323234.99
                  <span style="font-size: 18px;">元</span>
                </div>
                <div class="income">
                  <img src="https://football-go.oss-cn-hangzhou.aliyuncs.com/pic/15-icon/300-allOrder.png" />
                  <div class="topic">线上营收</div>
                </div>
              </div>
              <div class="offline_info" style="margin-top: 20px;">
                <div class="value">
                  323234.99
                  <span style="font-size: 18px;">元</span>
                </div>
                <div class="income">
                  <img src="https://football-go.oss-cn-hangzhou.aliyuncs.com/pic/15-icon/301-pitchOrder.png" />
                  <div class="topic">线下营收</div>
                </div>
              </div>
            </div> -->
    <!-- <div class="count_img">
              <img src="https://football-go.oss-cn-hangzhou.aliyuncs.com/pic/15-icon/660-left.png" />
            </div> -->
    <!-- <div class="row-data">
              <div class="interlock">
                <div class="value">
                 32
                  <span style="font-size: 18px;">场</span>
                </div>
                <div class="income">
                  <img src="https://football-go.oss-cn-hangzhou.aliyuncs.com/pic/15-icon/300-allOrder.png" />
                  <div class="topic">整场订单</div>
                </div>
              </div>
              <div class="challenge">
                <div class="value">
                  29
                  <span style="font-size: 18px;">人</span>
                </div>
                <div class="income">
                  <img src="https://football-go.oss-cn-hangzhou.aliyuncs.com/pic/15-icon/301-pitchOrder.png" />
                  <div class="topic">拼场订单</div>
                </div>
              </div>
              <div class="business">
                <div class="value">
                  2
                  <span style="font-size: 18px;">人</span>
                </div>
                <div class="income">
                  <img src="https://football-go.oss-cn-hangzhou.aliyuncs.com/pic/15-icon/302-feipanOrder.png" />
                  <div class="topic">飞盘订单</div>
                </div>
              </div>
            </div>
          </div> -->
    <!-- </div>
      </div> -->
    <el-row :gutter="20" class="mt-5">
      <el-col :span="12">
        <normal-card>
          <div class="chartPic h400 w-full" id="transactionChart"></div>
        </normal-card>
      </el-col>
      <el-col :span="12">
        <normal-card>
            <div style="padding: 20px;" id="orderChart" class="chartPic h400 w-full"></div>
        </normal-card>
      </el-col>
    </el-row>

    <el-row :gutter="20" style="margin-top: 20px;">
      <el-col :span="12">
        <normal-card>
            <div class="dayTotal">
              <div class="item">
                <span><i class="el-icon-d-arrow-left"></i>上一月</span>
              </div>
              <div class="item">
                <span>上一月<i class="el-icon-d-arrow-right"></i></span>
              </div>
            </div>
            <div style="padding: 20px;" id="dayOption" class="h400 w-full"></div>
        </normal-card>
      </el-col>
      <el-col :span="12">
        <normal-card>
            <div class="dayTotal">
              <div class="item">
                <span><i class="el-icon-d-arrow-left"></i>上一年</span>
              </div>
              <div class="item">
                <span>下一年<i class="el-icon-d-arrow-right"></i></span>
              </div>
            </div>
            <div style="padding: 20px;" id="mouthOption" class="h400 w-full"></div>
        </normal-card>
      </el-col>
    </el-row>
    <!-- </div> -->
    <div class="bottom-btn">
      <el-button class="button" @click="fieldMoney">交易明细</el-button>
    </div>
    
    <el-dialog title="交易流水" :visible.sync="dialogMoneyVisible">
      <div v-for="(item, index) in tradeList" :key="index">
        <div class="list-item">
          <div class="date">
            <img src='https://football-go.oss-cn-hangzhou.aliyuncs.com/pic/15-icon/220-riqi.jpg' class="date-img" />
            <span>{{ item.day }}</span>
          </div>
          <div class="item-area">
            <div class="priceInfo">
              <div class="income">
                <div class="price-labeled"></div>
                <div class="Withdrawals">
                  <span>提现金额：</span>
                  <span class="bold" style="color: #f00;">￥{{ item.withdrawAmount }}</span>
                </div>
              </div>
              <div class="income">
                <div class="price-labeled"></div>
                <div class="Withdrawals">
                  <span>提现状态：</span>
                  <span class="bold" v-if="item.status == '0'">已申请</span>
                  <span class="bold" v-if="item.status == '1'">已结算</span>
                  <span class="bold" v-if="item.status == '-1'">已拒绝</span>
                  <span class="bold" v-if="item.status === null">—</span>
                </div>
              </div>
            </div>
            <div class="vertical"></div>
            <div class="today">
              <span style="font-size: 15px; margin-bottom: 5px;">今日收入</span>
              <div>
                <span style="font-size: 18px; font-weight: bold;">+ {{ item.totalAmount }}</span> 元 >
              </div>
            </div>
            <div class="vertical"></div>
            <div class="order-date">
              <div class="order-info" style="background-color: #f5a623;">
                <div class="order-labeled"></div>
                <div class="order">整场订单:{{ item.wholeCount }}场</div>
              </div>
              <div class="order-info" style="background-color:rgb(230, 21, 21);">
                <div class="order-labeled"></div>
                <div class="order">拼场订单:{{ item.couponCount }}人</div>
              </div>
              <div class="order-info" style="background-color: rgb(33, 141, 204);">
                <div class="order-labeled"></div>
                <div class="order">飞盘订单:{{ item.frisbeeCount }}人</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Transaction from "../../apidata/Transaction";
import mouthOption from "../../apidata/mouthOption";
import dayOption from "../../apidata/dayOption";
import orderType from "../../apidata/orderType";
import normalCard from '@/common/components/normal/NormalCard';
import * as echarts from "echarts";
import TopData from '../../common/components/normal/TopData.vue';
export default {
  components: { normalCard,TopData },
  name: "DataStatistic",
  data() {
    return {
      dialogMoneyVisible: false,
      totalUser: 0,
      monthIncrementUser: 0,
      totalPagediv: 0,
      todayPagediv: 0,
      adviceCount: 0,
      complaintCount: 0,
      Transaction: Transaction,
      mouthOption: mouthOption,
      dayOption: dayOption,
      orderType: orderType,
      tradeList: [
        {
          couponCount: 0,
          day: "2023-08-30",
          frisbeeCount: 0,
          status: 1,
          totalAmount: 0,
          wholeCount: 0,
          withdrawAmount: 0
        },
        {
          couponCount: 0,
          day: "2023-08-30",
          frisbeeCount: 0,
          status: 1,
          totalAmount: 0,
          wholeCount: 0,
          withdrawAmount: 0
        }
      ],

      // 当前球场
      currentField: {},
      // 球场列表
      fieldList: [],
      fieldDialogVisible: false
    };
  },
  methods: {
    init() {
      this.dataTotal();
      this.currentField = localStorage.getItem('currentField') ? JSON.parse(localStorage.getItem('currentField')) : {};
      console.log(this.currentField);
    },
    async dataTotal() {
      //交易分布
      let transactionPie = echarts.init(document.getElementById("transactionChart"));
      transactionPie.setOption(Transaction);
      // 订单分区
      let orderPie = echarts.init(document.getElementById("orderChart"));
      orderPie.setOption(orderType);
      //日收益
      let dayHistogram = echarts.init(document.getElementById("dayOption"));
      dayHistogram.setOption(dayOption);
      // 月收益
      let mouthHistogram = echarts.init(document.getElementById("mouthOption"));
      mouthHistogram.setOption(mouthOption);
    },
    fieldMoney() {
      this.dialogMoneyVisible = true
    },

    // 点击切换球场图片
    switchField() {
      this.$http.get('/Field/GetFieldList').then(response => {
        if (response.res.isSuccess) {
          this.fieldList = response.res.data || [];
          this.fieldDialogVisible = true;
        }
      });
    },
    // 判断该行球场是否为当前选择球场
    checkCurrentField(row) {
      return row.fieldId == localStorage.getItem('fieldId');
    },
    // 切换球场操作
    handleSwitch(row) {
      this.$confirm('确认切换球场吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        localStorage.setItem('fieldId', row.fieldId);
        localStorage.setItem('currentField', JSON.stringify(row));
        location.reload();
      });
    }
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  margin-top: 20px;
}

// 图表
.onLine,
.Offline {
  border-radius: 10px;
  width: 48%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  background-color: #134f7a;
  border: 1px solid #666;
  width: 25px;
  height: 12px;

}

.revenueTitle {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.Transaction,
.monthOption {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.dayTotal {
  margin: 10px;
  display: flex;
  color: #fff;
  justify-content: space-between;
}

.chartPic {
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.item {
  color: #fff;
}

// 按钮
.bottom-btn {
  margin-top: 80px;
  display: flex;
  justify-content: center;
}

.button {
  background-color: #159cd1;
  width: 150px;
  color: #fff;
  font-size: 16px;
}

// 球场收益
.data_info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.totleIn {
  font-size: 20px;
  font-weight: 550;
}

.total-span {
  display: flex;
  align-items: center;
  font-size: 18px;
  margin-right: 8px;
}

.total-span img {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.totalTr {
  width: 100%;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.totalTr .fieldName {
  display: flex;
  align-items: center;
  font-size: 18px;
  margin-right: 8px;
}

.row-data {
  display: flex;
  align-items: center;
  margin-top: 20px;
  width: 80%;
  justify-content: space-between;
}

.row-data img {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.vip-number,
.online_info,
.rates-income,
.preset,
.offline_info,
.business,
.interlock,
.challenge {
  display: inline-table;
  width: 60px;
  height: 30px;
  border-radius: 10px;
  color: #253546;
  padding: 5px 10px;
  margin: 10px 0;
}

.income {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  padding: 5px;
}

.total-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
  background-color: rgb(51, 64, 84);
  color: #fff;
  padding: 30px 0;
  border-radius: 10px;
}

.total-area {
  width: 80%;

}

.total-data .value {
  width: 125px;
  font-size: 18px;
  color: #253546;
  font-weight: 530;
}

.count_img img {
  margin-top: 20px;
  width: 40px;
  height: 240px;
}

.detailBtn {
  position: absolute;
  top: 228%;
  bottom: 20px;
  width: 45%;
  height: 80px;
  line-height: 80px;
  color: #3af197;
  background-color: #222;
  margin: 20px auto;
  border-radius: 30px;
  font-size: 18px;
  font-weight: bold;
}

// 交易明细弹窗
.lists .list-item {
  margin: 10px;
  margin-top: 0;
  padding: 5px;
  background-color: #fff;
  border-radius: 10px;
  border-bottom: 1px solid rgb(150, 150, 150)a0;
}

.date {
  display: flex;
  align-items: center;
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
}

.date-img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.item-area {
  display: flex;
  justify-content: space-around;
  margin: 10px;
}

.priceInfo {
  display: inline-block;
  text-align: left;
}

.order-date {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.list-item .income {
  display: flex;
  align-items: center;
  width: 200px;
  padding-top: 5px;
}

.order-info {
  display: flex;
  align-items: center;
  padding: 3px;
  border-radius: 10px;
}

.price-labeled {
  width: 15px;
  height: 15px;
  border-radius: 25%;
  margin-right: 10px;
  background-color: #134f7a;
}

.vertical {
  background-color: #000;
  width: 1px;
  height: 80px;
  margin-bottom: 10px;
}

.today {
  text-align: right;
  padding-top: 15px;
}

.bold {
  font-size: 15px;
  font-weight: bold;
}

.order-labeled {
  width: 10px;
  height: 10px;
  border-radius: 25%;
  margin-right: 10px;
  background-color: #fff;
}

.order {
  font-size: 10px;
  text-align: center;
  color: #fff;
}

.Withdrawals text {
  font-size: 10px;
  text-align: center;
}
</style>
